import { Avatar } from '@mui/material'
import {
    LeafBackgroundIcon,
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

export interface SocialAvatarProps {
    initials: string
    imageUrl?: string
    isGroup?: boolean
}

const SocialAvatar: React.FC<SocialAvatarProps> = ({
    initials,
    imageUrl,
    isGroup
}) => {
    const theme = useTheme()

    return (
        <>
            {isGroup ? (
                <LeafBackgroundIcon
                    color={'secondary'}
                    icon={<LeafIcon icon={'user-group'} />}
                />
            ) : (
                <Avatar
                    src={imageUrl}
                    aria-hidden="true"
                    data-testid="social-avatar"
                    alt=""
                    sx={{
                        backgroundColor: theme.palette.accent.main,
                        color: theme.palette.accent.contrastText
                    }}
                >
                    {!imageUrl && (
                        <CoreTypography variant="caption" fontSize="large">
                            {initials}
                        </CoreTypography>
                    )}
                </Avatar>
            )}
        </>
    )
}

export default React.memo(SocialAvatar)
