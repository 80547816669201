import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeType } from '../../shared/enums/challengeType'

const messages = defineMessages({
    companyChallenge: {
        defaultMessage: 'Company Challenge',
        description: 'description for company challenge'
    },
    groupChallenge: {
        defaultMessage: 'Group Challenge',
        description: 'description for group challenge'
    },
    personalChallenge: {
        defaultMessage: 'Personal Challenge',
        description: 'description for personal challenge'
    },
    privateChallenge: {
        defaultMessage: 'Private Challenge',
        description: 'description for private challenge'
    }
})

export const useChallengeTypeIcon = (
    challengeType?: ChallengeType,
    forcePrivateVerbiage?: boolean
) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    return useMemo(() => {
        switch (challengeType) {
            case ChallengeType.company:
                return {
                    icon: (
                        <LeafIcon
                            icon={'buildings'}
                            fontSize={'small'}
                            iconStyle={'solid'}
                            sx={{ color: theme.palette.text.secondary }}
                        />
                    ),
                    title: formatMessage(messages.companyChallenge)
                }
            case ChallengeType.group:
                return {
                    icon: (
                        <LeafIcon
                            icon={'users'}
                            fontSize={'small'}
                            iconStyle={'solid'}
                            sx={{ color: theme.palette.text.secondary }}
                        />
                    ),
                    title: forcePrivateVerbiage
                        ? formatMessage(messages.privateChallenge)
                        : formatMessage(messages.groupChallenge)
                }
            case ChallengeType.journey:
            case ChallengeType.biotype:
            case ChallengeType.prize:
            case ChallengeType.personal:
                return {
                    icon: (
                        <LeafIcon
                            icon={'user'}
                            fontSize={'small'}
                            iconStyle={'solid'}
                            sx={{ color: theme.palette.text.secondary }}
                        />
                    ),
                    title: formatMessage(messages.personalChallenge)
                }
            default:
                return { icon: null, title: null }
        }
    }, [challengeType, formatMessage, forcePrivateVerbiage, theme.palette])
}
